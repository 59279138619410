import { reactive, ref } from "vue";
import useMessage from "@/hooks/useMessage";
import { list } from "@/api/sys/sysRoleApi";

const useSysRole = () => {
  const { showErr } = useMessage();
  const loading = ref(false);
  const dataList = reactive({ list: [] });
  const params = reactive({ name: "" });
  const total = ref(0);

  const roleVo = reactive({
    name: "",
    remark: "",
  });

  const columns = [
    {
      title: "序号",
      align: "center",
      width: "80px",
      customRender: ({ index }) => {
        return index + 1;
      },
    },
    {
      title: "名称",
      align: "center",
      dataIndex: "name",
    },
    {
      title: "备注",
      align: "center",
      ellipsis: true,
      dataIndex: "remark",
    },
    {
      title: "操作",
      align: "center",
      width: "240px",
      slots: { customRender: "operation" },
    },
  ];

  const loadData = async (page, pageSize) => {
    loading.value = true;
    try {
      let res = await list(params, page, pageSize);
      dataList.list = res.data;
      total.value = res.total;
      loading.value = false;
    } catch (e) {
      loading.value = false;
      await showErr(e);
    }
  };

  return { dataList, columns, loading, roleVo, total, params, loadData };
};
export default useSysRole;
